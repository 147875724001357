import React from "react";

import AllRoutes from "./routes/Routes";
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import 'rsuite/dist/rsuite.min.css';

// For Default import Default.scss
import './assets/scss/Default.scss';



// Other
import './assets/scss/Landing.scss';
import "./assets/scss/Icons.scss";
// css
import './style.css'



const App = () => {
  return (
    <>
      <React.Fragment>
        <AllRoutes />
        <ToastContainer
          position="top-left"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </React.Fragment>
    </>
  );
};
export default App;
