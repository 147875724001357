import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import translationAr from "./locales/ar/translation.json";
import translationEs from "./locales/es/translation.json";
export const LANGUAGE_SESSION_KEY = "LanguageKey";

export const changeLanguage = async (language: string) => {
  sessionStorage.setItem(LANGUAGE_SESSION_KEY, language);
  const userLanguage = sessionStorage.getItem(LANGUAGE_SESSION_KEY) ?? "en";
  i18n.changeLanguage(userLanguage);
  window.location.reload();
  console.log("userLanguage =>", userLanguage);
};
// const name = i18n?.isInitialized && i18n?.t("name");
// console.log("name =>", name);
//translations
const resources = {
  ar: {
    translation: translationAr,
  },
  es: {
    translation: translationEs,
  },
};

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: "en", // use en if detected lng is not available

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
