import React, { Suspense, memo, useEffect, useMemo } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LayoutTypes } from "../constants/layout";
import { AppDispatch, RootState } from "../redux/store";
import DefaultLayout from "../layouts/Default";
import VerticalLayout from "../layouts/Vertical";
import DetachedLayout from "../layouts/Detached";
import {
  authProtectedFlattenRoutes,
  publicProtectedFlattenRoutes,
  superAdminMasterAuthProtectedFlattenRoutes,
  adminAuthProtectedRoutes,
  accountsFlattenRoutes,
  superAccountsFlattenRoutes,
  superOperationManagementRoutes,
} from "./index";
import { APICore } from "../helpers/api/apiCore";
import { userDetails } from "../redux/actions";
import { AuthType } from "../constants/menu";

const AllRoutes: React.FC = () => {
  const api = useMemo(() => new APICore(), []);
  const dispatch = useDispatch<AppDispatch>();
  const { layout, user } = useSelector((state: RootState) => ({
    layout: state.Layout,
    user: state.Auth.user,
  }));
  const Usercheckins = React.lazy(() => import("../pages/usercheckins"));

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (api.isUserAuthenticated()) {
      dispatch(userDetails());
    }
  }, [api, dispatch]);

  useEffect(() => {
    const getRedirectPath = () => {
      switch (user?.user_type) {
        case AuthType.SUPER_ADMIN:
          return "/super-admin/users";
        case AuthType.ADMIN:
          return "/admin/ticketbooking";
        case AuthType.STAFF:
          // return "/staff/dashboard";
          return "/staff/book-package";
        case AuthType.ACCOUNT:
          // return "/accounts/dashboard";
          return "/accounts/ticket-booking";
        case AuthType.HEAD_ACCOUNT:
          // return "/super-accounts/dashboard";
          return "/super-accounts/ticketbooking";
        case AuthType.OPERATION_MANAGER_ACCOUNT:
          return "/operation-manager/invoices";
        default:
          return "/admin/login";
      }
    };

    if (location.pathname === "/" || location.pathname === "/admin/login") {
      const redirectPath = getRedirectPath();
      if (redirectPath !== location.pathname) {
        navigate(redirectPath, { replace: true });
      }
    }
  }, [location.pathname, user?.user_type, navigate]);

  const getLayoutComponent = () => {
    switch (layout.layoutType) {
      case LayoutTypes.LAYOUT_VERTICAL:
        return VerticalLayout;
      case LayoutTypes.LAYOUT_DETACHED:
      default:
        return DetachedLayout;
    }
  };

  const LayoutComponent = getLayoutComponent();

  const renderRouteElement = (route: any) => {
    if (route.path === "/checkin") {
      return (
        <Suspense fallback={null}>
          <Usercheckins />
        </Suspense>
      );
    }
    return <DefaultLayout layout={layout}>{route.element}</DefaultLayout>;
  };

  const renderProtectedRoute = (route: any) => {
    return api.isUserAuthenticated() ? (
      <LayoutComponent>{route.element}</LayoutComponent>
    ) : (
      <Navigate to="/admin/login" />
    );
  };

  const getRoutesByUserType = () => {
    switch (user?.user_type) {
      case AuthType.SUPER_ADMIN:
        return superAdminMasterAuthProtectedFlattenRoutes;
      case AuthType.ADMIN:
        return adminAuthProtectedRoutes;
      case AuthType.STAFF:
        return authProtectedFlattenRoutes;
      case AuthType.ACCOUNT:
        return accountsFlattenRoutes;
      case AuthType.HEAD_ACCOUNT:
        return superAccountsFlattenRoutes;
      case AuthType.OPERATION_MANAGER_ACCOUNT:
        return superOperationManagementRoutes;
      default:
        return [];
    }
  };

  return (
    <Routes>
      {publicProtectedFlattenRoutes.map((route, idx) => (
        <Route
          key={idx}
          path={route.path}
          element={renderRouteElement(route)}
        />
      ))}
      {getRoutesByUserType().map((route, idx) => (
        <Route
          key={idx}
          path={route.path}
          element={renderProtectedRoute(route)}
        />
      ))}
    </Routes>
  );
};

export default memo(AllRoutes);
