import i18n from "../i18n";

enum AuthType {
  SUPER_ADMIN = "super_admin",
  HEAD_ACCOUNT = "head_account",
  OPERATION_MANAGER_ACCOUNT = "operations",
  ADMIN = "admin",
  STAFF = "staff",
  ACCOUNT = "account",
  AGENT = "agent",
}

export interface MenuItemTypes {
  key: string;
  label: string;
  isTitle?: boolean;
  icon?: string;
  url?: string;
  badge?: {
    variant: string;
    text: string;
  };
  parentKey?: string;
  target?: string;
  children?: MenuItemTypes[];
}

const OPERATION_MANAGER_ROUTES: MenuItemTypes[] = [
  { key: "menu", label: i18n?.t("Operations management"), isTitle: true },
  {
    key: "invoices",
    label: i18n?.t("Invoices"),
    parentKey: "master",
    icon: "bi-dash",
    badge: { variant: "success", text: "" },
    url: "/operation-manager/invoices",
  },
  {
    key: "bus-maintenances",
    label: i18n?.t("Bus Maintenances"),
    parentKey: "master",
    icon: "bi-dash",
    badge: { variant: "success", text: "" },
    url: "/operation-manager/bus-maintenances",
  },
  {
    key: "user-list",
    label: i18n?.t("Users List"),
    parentKey: "master",
    icon: "bi-dash",
    badge: { variant: "success", text: "" },
    url: "/operation-manager/users-list",
  },
  {
    key: "expense",
    label: i18n?.t("Expense"),
    isTitle: true,
    icon: "bi-cash-coin",
  },
  {
    key: "expensereport",
    label: i18n?.t("Expense Report"),
    isTitle: false,
    icon: "bi-bar-chart",
    // badge: { variant: "success", text: "4" },
    url: "/operation-manager/expense-report",
    parentKey: "expense",
  },

  {
    key: "addexpense",
    label: i18n?.t("Add Expense"),
    isTitle: false,
    icon: "bi-cash-coin",
    url: "/operation-manager/add-expense",
    parentKey: "expense",
  },
  {
    key: "expensehistory",
    label: i18n?.t("Expense History"),
    isTitle: false,
    icon: "bi-clock-history",
    url: "/operation-manager/expense-history",
    parentKey: "expense",
  },
  {
    key: "refund-request-history",
    label: i18n?.t("Refund Request History"),
    isTitle: false,
    icon: "bi-clock-history",
    url: "/operation-manager/refund-request-history",
    parentKey: "expense",
  },
  {
    key: "checkedinlist",
    label: i18n?.t("Checked In List"),
    isTitle: false,
    icon: "bi-calendar-check",
    url: "/operation-manager/checkedinlist",
  },
  {
    key: "addDeposit",
    label: i18n?.t("Add Deposit"),
    isTitle: false,
    icon: "bi-wallet",
    url: "/operation-manager/add-deposit",
    parentKey: "expense",
  },
  {
    key: "DepositReport",
    label: i18n?.t("Deposit Report"),
    isTitle: false,
    icon: "bi-list-columns-reverse",
    url: "/operation-manager/deposit-report",
    parentKey: "expense",
  },
  {
    key: "SalesReport",
    label: i18n?.t("Sales Report"),
    isTitle: false,
    icon: "bi-clipboard-data",
    url: "/operation-manager/sales-report",
    parentKey: "expense",
  },
  {
    key: "agent-report",
    label: i18n?.t("Agent Credit Report"),
    isTitle: false,
    icon: "bi-clipboard-data",
    url: "/operation-manager/agent-report",
  },
  {
    key: "agentTopUpReport",
    label: i18n?.t(`Agent Top-up Report`),
    isTitle: false,
    icon: "bi-clock-history",
    url: "/operation-manager/topup-report",
  },
  // {
  //   key: "ticket",
  //   label: "Ticket Book",
  //   isTitle: false,
  //   icon: "bi-ticket",
  //   children: [
  //     {
  //       key: "ticket-booking",
  //       label: "Ticket Booking",
  //       isTitle: false,
  //       icon: "mdi mdi-ticket-percent-outline",
  //       url: "/operation-manager/ticket-booking",
  //       parentKey: "ticket",
  //     },
  //     {
  //       key: "update-booking",
  //       label: "Update Booking",
  //       isTitle: false,
  //       icon: "bi-calendar-check",
  //       url: "/operation-manager/update-booking",
  //       parentKey: "ticket",
  //     },
  //   ],
  // },
  // {
  //   key: "consignment",
  //   label: "Consignment",
  //   isTitle: false,
  //   icon: "bi-truck",
  //   children: [
  {
    key: "consignmentreport",
    label: i18n?.t("Consignment Report"),
    isTitle: false,
    icon: "bi-clipboard-minus",
    url: "/operation-manager/consignmentreport",
    parentKey: "consignment",
  },
  //     {
  //       key: "createconsignment",
  //       label: "Create Consignment",
  //       isTitle: false,
  //       icon: "bi-truck",
  //       url: "/operation-manager/createconsignment",
  //       parentKey: "consignment",
  //     },
  {
    key: "consignmenthistory",
    label: i18n?.t("Consignment History"),
    isTitle: false,
    icon: "bi-arrow-down-up",
    url: "/operation-manager/consignmenthistory",
    parentKey: "consignment",
  },
  {
    key: "traveldatechangehistory",
    label: i18n?.t(`Travel Date Change History`),
    isTitle: false,
    icon: "bi-clock-history",
    url: "/operation-manager/travel-date-change-history",
  },
  //     {
  //       key: "consignmenttracking",
  //       label: "Consignment Tracking",
  //       isTitle: false,
  //       icon: "bi-arrow-left-right",
  //       url: "/operation-manager/consignmenttracking",
  //       parentKey: "consignment",
  //     },
  //     {
  //       key: "consignment-tracking-history",
  //       label: "Consignment Tracking History",
  //       isTitle: false,
  //       icon: "bi-clock-history",
  //       url: "/operation-manager/consignment-tracking-history",
  //       parentKey: "consignment",
  //     },
  //   ],
  // },
];

const SUPER_ACCOUNTS_MENU_ITEMS: MenuItemTypes[] = [
  { key: "menu", label: i18n?.t("MENU"), isTitle: true },
  {
    key: "dashboard",
    label: i18n?.t("Dashboard"),
    isTitle: false,
    icon: "bi-speedometer2",
    // badge: { variant: "success", text: "4" },
    url: "/super-accounts/dashboard",
  },
  {
    key: "expensereport",
    label: i18n?.t("Expense Report"),
    isTitle: false,
    icon: "bi-bar-chart",
    // badge: { variant: "success", text: "4" },
    url: "/super-accounts/expense-report",
    parentKey: "expense",
  },
  {
    key: "expensehistory",
    label: i18n?.t("Expense History"),
    isTitle: false,
    icon: "bi-clock-history",
    url: "/super-accounts/expense-history",
    parentKey: "expense",
  },
  {
    key: "refund-request-history",
    label: i18n?.t("Refund Request History"),
    isTitle: false,
    icon: "bi-clock-history",
    url: "/super-accounts/refund-request-history",
    parentKey: "expense",
  },
  {
    key: "checkedinlist",
    label: i18n?.t("Checked In List"),
    isTitle: false,
    icon: "bi-calendar-check",
    url: "/super-accounts/checkedinlist",
  },
  {
    key: "salesreport",
    label: i18n?.t("Sales Report"),
    isTitle: false,
    icon: "bi-clipboard-data",
    url: "/super-accounts/salesreport",
  },
  {
    key: "agent-report",
    label: i18n?.t("Agent Credit Report"),
    isTitle: false,
    icon: "bi-clipboard-data",
    url: "/super-accounts/agent-report",
  },
  {
    key: "balance-report",
    label: i18n?.t("Balance Sheet"),
    isTitle: false,
    icon: "bi bi-file-spreadsheet-fill",
    url: "/super-accounts/balance-report",
  },
  {
    key: "income-report",
    label: i18n?.t("Income Statement"),
    isTitle: false,
    icon: "bi bi-wallet2",
    url: "/super-accounts/incomereport",
    parentKey: "expense",
  },
  {
    key: "trial-balance",
    label: i18n?.t("Trial Balance"),
    isTitle: false,
    icon: "bi bi-calculator",
    url: "/super-accounts/trial-balance",
    parentKey: "expense",
  },
  { key: "ticket", label: i18n?.t("ticket section"), isTitle: true },
  {
    key: "depositReport",
    label: i18n?.t(`Deposit Report`),
    isTitle: false,
    icon: "bi-clock-history",
    url: "/super-accounts/deposit-report",
  },
  {
    key: "agentTopUpReport",
    label: i18n?.t(`Agent Top-up Report`),
    isTitle: false,
    icon: "bi-clock-history",
    url: "/super-accounts/topup-report",
  },
  {
    key: "traveldatechangehistory",
    label: i18n?.t(`Travel Date Change History`),
    isTitle: false,
    icon: "bi-clock-history",
    url: "/super-accounts/travel-date-change-history",
  },
  {
    key: "bookinghistory",
    label: i18n?.t("Booking History"),
    isTitle: false,
    icon: "bi-calendar4-range",
    url: "/super-accounts/bookinghistory",
  },
  {
    key: "users-list",
    label: i18n?.t("Users List"),
    icon: "bi-dash",
    badge: { variant: "success", text: "" },
    url: "/super-accounts/users-list",
  },
  { key: "ticket", label: "Consignment section", isTitle: true },
  {
    key: "consignmentreport",
    label: i18n?.t("Consignment Report"),
    isTitle: false,
    icon: "bi-clipboard-minus",
    url: "/super-accounts/consignmentreport",
    parentKey: "consignment",
  },
  {
    key: "consignmenthistory",
    label: i18n?.t("Consignment History"),
    isTitle: false,
    icon: "bi-arrow-down-up",
    url: "/super-accounts/consignmenthistory",
    parentKey: "consignment",
  },
  {
    key: "consignmenttracking",
    label: i18n?.t("Consignment Tracking"),
    isTitle: false,
    icon: "bi-arrow-left-right",
    url: "/super-accounts/consignmenttracking",
    parentKey: "consignment",
  },
  {
    key: "consignment-tracking-history",
    label: i18n?.t("Consignment Tracking History"),
    isTitle: false,
    icon: "bi-clock-history",
    url: "/super-accounts/consignment-tracking-history",
    parentKey: "consignment",
  },
];

const ACCOUNTS_MENU_ITEMS: MenuItemTypes[] = [
  { key: "menu", label: i18n?.t("Expense"), isTitle: true },
  // {
  //   key: "dashboard",
  //   label: "Dashboard",
  //   isTitle: false,
  //   icon: "bi-speedometer2",
  //   // badge: { variant: "success", text: "4" },
  //   url: "/accounts/dashboard",
  // },
  {
    key: "expensereport",
    label: i18n?.t("Expense Report"),
    isTitle: false,
    icon: "bi-bar-chart",
    // badge: { variant: "success", text: "4" },
    url: "/accounts/expense-report",
    parentKey: "expense",
  },

  {
    key: "addexpense",
    label: i18n?.t("Add Expense"),
    isTitle: false,
    icon: "bi-cash-coin",
    url: "/accounts/add-expense",
    parentKey: "expense",
  },
  {
    key: "expensehistory",
    label: i18n?.t("Expense History"),
    isTitle: false,
    icon: "bi-clock-history",
    url: "/accounts/expense-history",
    parentKey: "expense",
  },
  {
    key: "refund-request-history",
    label: i18n?.t("Refund Request History"),
    isTitle: false,
    icon: "bi-clock-history",
    url: "/accounts/refund-request-history",
    parentKey: "expense",
  },
  {
    key: "addDeposit",
    label: i18n?.t("Add Deposit"),
    isTitle: false,
    icon: "bi-wallet",
    url: "/accounts/add-deposit",
    parentKey: "expense",
  },
  {
    key: "DepositReport",
    label: i18n?.t("Deposit Report"),
    isTitle: false,
    icon: "bi-list-columns-reverse",
    url: "/accounts/deposit-report",
    parentKey: "expense",
  },
  { key: "ticket", label: i18n?.t("ticket booking"), isTitle: true },
  {
    key: "ticketbooking",
    label: i18n?.t("Ticket Booking"),
    isTitle: false,
    icon: "mdi mdi-ticket-percent-outline",
    url: "/accounts/ticket-booking",
  },
  {
    key: "updatebooking",
    label: i18n?.t("Update Booking"),
    isTitle: false,
    icon: "bi-calendar-check",
    url: "/accounts/update-booking",
  },
  // {
  //   key: "refund-booking",
  //   label: "Refund Ticket",
  //   isTitle: false,
  //   icon: "dripicons-ticket",
  //   url: "/accounts/refund-booking",
  // },
  {
    key: "traveldatechangehistory",
    label: i18n?.t(`Travel Date Change History`),
    isTitle: false,
    icon: "bi-clock-history",
    url: "/accounts/travel-date-change-history",
  },
  {
    key: "bookinghistory",
    label: i18n?.t("Booking History"),
    isTitle: false,
    icon: "bi-calendar4-range",
    url: "/accounts/bookinghistory",
  },
  {
    key: "checkedinlist",
    label: i18n?.t("Checked In List"),
    isTitle: false,
    icon: "bi-calendar-check",
    url: "/accounts/checkedinlist",
  },
  {
    key: "salesreport",
    label: i18n?.t("Sales Report"),
    isTitle: false,
    icon: "bi-clipboard-data",
    url: "/accounts/salesreport",
    parentKey: "expense",
  },
  {
    key: "consignment",
    label: i18n?.t("Consignment section"),
    isTitle: false,
    icon: "bi-truck",
    children: [
      {
        key: "consignmentreport",
        label: i18n?.t("Consignment Report"),
        isTitle: false,
        icon: "bi-clipboard-minus",
        url: "/accounts/consignmentreport",
        parentKey: "consignment",
      },
      {
        key: "createconsignment",
        label: i18n?.t("Create Consignment"),
        isTitle: false,
        icon: "bi-truck",
        url: "/accounts/createconsignment",
        parentKey: "consignment",
      },
      {
        key: "consignmenthistory",
        label: i18n?.t("Consignment History"),
        isTitle: false,
        icon: "bi-arrow-down-up",
        url: "/accounts/consignmenthistory",
        parentKey: "consignment",
      },
      {
        key: "consignmenttracking",
        label: i18n?.t("Consignment Tracking"),
        isTitle: false,
        icon: "bi-arrow-left-right",
        url: "/accounts/consignmenttracking",
        parentKey: "consignment",
      },
      {
        key: "consignment-tracking-history",
        label: i18n?.t("Consignment Tracking History"),
        isTitle: false,
        icon: "bi-clock-history",
        url: "/accounts/consignment-tracking-history",
        parentKey: "consignment",
      },
    ],
  },
];

const MENU_ITEMS: MenuItemTypes[] = [
  { key: "menu", label: i18n?.t("MENU"), isTitle: true },
  {
    key: "dashboard",
    label: i18n?.t("Dashboard"),
    isTitle: false,
    icon: "bi-speedometer2",
    // badge: { variant: "success", text: "4" },
    url: "/staff/dashboard",
  },
  {
    key: "refund-request-history",
    label: i18n?.t("Refund Request History"),
    isTitle: false,
    icon: "bi-clock-history",
    url: "/staff/refund-request-history",
    parentKey: "expense",
  },
  { key: "ticket", label: i18n?.t("ticket booking"), isTitle: true },
  {
    key: "apps-book-package",
    label: i18n?.t("Ticket Booking"),
    isTitle: false,
    icon: "bi-calendar2-check-fill",
    url: "/staff/book-package",
  },
  {
    key: "update-booking",
    label: i18n?.t("Update Booking"),
    isTitle: false,
    icon: "bi-calendar-check",
    url: "/staff/update-booking",
  },
  {
    key: "apps-change-book-package",
    label: i18n?.t(`Travel Date Change History`),
    isTitle: false,
    icon: "bi-clock-history",
    url: "/staff/travel-date-change-history",
  },
  // {
  //   key: "apps-request-visa",
  //   label: "Request Visa",
  //   isTitle: false,
  //   icon: "bi-passport",
  //   url: "/request-visa",
  // },
  // {
  //   key: "apps-accommondation",
  //   label: "Accommondation",
  //   isTitle: false,
  //   icon: "bi-box-arrow-in-right",
  //   url: "/accommondation",
  // },
  // {
  //   key: "apps-existfee",
  //   label: "Exist Fee",
  //   isTitle: false,
  //   icon: "bi-door-closed",
  //   url: "/staff/existfee",
  // },
  {
    key: "apps-checkin",
    label: i18n?.t("Check In / Exist Fee"),
    isTitle: false,
    icon: "bi-clipboard2-check",
    url: "/staff/checkedlist",
  },
  {
    key: "apps-bookingrequest",
    label: i18n?.t("Booking History"),
    isTitle: false,
    icon: "bi-box-arrow-in-down-right",
    url: "/staff/bookingrequest",
  },
  // {
  //   key: "apps-visarequest",
  //   label: "Visa Requests",
  //   isTitle: false,
  //   icon: "bi-passport-fill",
  //   url: "/visarequests",
  // },
  { key: "reports", label: i18n?.t("REPORTS"), isTitle: true },
  // {
  //   key: "booking-report",
  //   label: "Booking Report",
  //   isTitle: false,
  //   icon: "bi-flag-fill",
  //   url: "/staff/booking-report",
  // },
  {
    key: "consignmentreport",
    label: i18n?.t("Consignment Report"),
    isTitle: false,
    icon: "bi-clipboard-minus",
    url: "/staff/consignmentreport",
    parentKey: "consignment",
  },
  {
    key: "salesreport",
    label: i18n?.t("Sales Report"),
    isTitle: false,
    icon: "bi-clipboard-data",
    url: "/staff/salesreport",
  },
  { key: "consignment", label: i18n?.t("Consignment"), isTitle: true },
  {
    key: "createconsignment",
    label: i18n?.t("Create Consignment"),
    isTitle: false,
    icon: "bi-truck",
    url: "/staff/createconsignment",
    parentKey: "consignment",
  },
  {
    key: "consignmenthistory",
    label: i18n?.t("Consignment History"),
    isTitle: false,
    icon: "bi-arrow-down-up",
    url: "/staff/consignmenthistory",
    parentKey: "consignment",
  },
  {
    key: "consignmenttracking",
    label: i18n?.t("Consignment Tracking"),
    isTitle: false,
    icon: "bi-arrow-left-right",
    url: "/staff/consignmenttracking",
    parentKey: "consignment",
  },
  {
    key: "consignment-tracking-history",
    label: i18n?.t("Consignment Tracking History"),
    isTitle: false,
    icon: "bi-clock-history",
    url: "/staff/consignment-tracking-history",
    parentKey: "consignment",
  },
];

const ADMIN_MENU_ITEMS: MenuItemTypes[] = [
  { key: "expense", label: i18n?.t("Expenses"), isTitle: true },
  // {
  //   key: "expense",
  //   label: "Expense",
  //   isTitle: false,
  //   icon: "bi-cash-coin",
  //   children: [
  {
    key: "expensereport",
    label: i18n?.t("Expense Report"),
    isTitle: false,
    icon: "bi-bar-chart",
    // badge: { variant: "success", text: "4" },
    url: "/admin/expense-report",
    parentKey: "expense",
  },

  {
    key: "addexpense",
    label: i18n?.t("Add Expense"),
    isTitle: false,
    icon: "bi-cash-coin",
    url: "/admin/add-expense",
    parentKey: "expense",
  },
  {
    key: "expensehistory",
    label: i18n?.t("Expense History"),
    isTitle: false,
    icon: "bi-clock-history",
    url: "/admin/expense-history",
    parentKey: "expense",
  },
  {
    key: "refund-request-history",
    label: i18n?.t("Refund Request History"),
    isTitle: false,
    icon: "bi-clock-history",
    url: "/admin/refund-request-history",
    parentKey: "expense",
  },
  {
    key: "addDeposit",
    label: i18n?.t("Add Deposit"),
    isTitle: false,
    icon: "bi-wallet",
    url: "/admin/add-deposit",
    parentKey: "expense",
  },
  {
    key: "DepositReport",
    label: i18n?.t("Deposit Report"),
    isTitle: false,
    icon: "bi-list-columns-reverse",
    url: "/admin/deposit-report",
    parentKey: "expense",
  },
  //   ],
  // },
  { key: "menu", label: i18n?.t("Ticket Booking"), isTitle: true },
  {
    key: "ticketbooking",
    label: i18n?.t("Ticket Booking"),
    isTitle: false,
    icon: "mdi mdi-ticket-percent-outline",
    url: "/admin/ticketbooking",
  },
  {
    key: "updatebooking",
    label: i18n?.t("Update Booking"),
    isTitle: false,
    icon: "bi-calendar-check",
    url: "/admin/update-booking",
  },
  // {
  //   key: "refund-booking",
  //   label: "Refund Ticket",
  //   isTitle: false,
  //   icon: "dripicons-ticket",
  //   url: "/admin/refund-booking",
  // },
  {
    key: "traveldatechangehistory",
    label: i18n?.t(`Travel Date Change History`),
    isTitle: false,
    icon: "bi-clock-history",
    url: "/admin/travel-date-change-history",
  },
  {
    key: "bookinghistory",
    label: i18n?.t("Booking History"),
    isTitle: false,
    icon: "bi-calendar4-range",
    url: "/admin/bookinghistory",
  },
  {
    key: "checkedinlist",
    label: i18n?.t("Checked In List"),
    isTitle: false,
    icon: "bi-calendar-check",
    url: "/admin/checkedinlist",
  },
  {
    key: "salesreport",
    label: i18n?.t("Sales Report"),
    isTitle: false,
    icon: "bi-clipboard-data",
    url: "/admin/salesreport",
  },
  {
    key: "consignment",
    label: i18n?.t("Consignment"),
    isTitle: true,
    icon: "bi-truck",
  },
  // children: [
  {
    key: "consignmentreport",
    label: i18n?.t("Consignment Report"),
    isTitle: false,
    icon: "bi-clipboard-minus",
    url: "/admin/consignmentreport",
    parentKey: "consignment",
  },
  {
    key: "createconsignment",
    label: i18n?.t("Create Consignment"),
    isTitle: false,
    icon: "bi-truck",
    url: "/admin/createconsignment",
    parentKey: "consignment",
  },
  {
    key: "consignmenthistory",
    label: i18n?.t("Consignment History"),
    isTitle: false,
    icon: "bi-arrow-down-up",
    url: "/admin/consignmenthistory",
    parentKey: "consignment",
  },
  {
    key: "consignmenttracking",
    label: i18n?.t("Consignment Tracking"),
    isTitle: false,
    icon: "bi-arrow-left-right",
    url: "/admin/consignmenttracking",
    parentKey: "consignment",
  },
  {
    key: "consignment-tracking-history",
    label: i18n?.t("Consignment Tracking History"),
    isTitle: false,
    icon: "bi-clock-history",
    url: "/admin/consignment-tracking-history",
    parentKey: "consignment",
  },
  // ],
  // },
  // {
  //   key: "clientconsignment",
  //   label: "Client Consignment",
  //   isTitle: false,
  //   icon: "bi-truck",
  //   children: [
  //     {
  //       key: "createclientconsignment",
  //       label: "Create Client Consignment",
  //       isTitle: false,
  //       icon: "bi-truck",
  //       url: "/admin/createclientconsignment",
  //       parentKey: "clientconsignment",
  //     },
  //     {
  //       key: "clientconsignmenthistory",
  //       label: "Client Consignment History",
  //       isTitle: false,
  //       icon: "bi-arrow-down-up",
  //       url: "/admin/clientconsignmenthistory",
  //       parentKey: "clientconsignment",
  //     },
  //     {
  //       key: "clientconsignmentreport",
  //       label: "Client Consignment Report",
  //       isTitle: false,
  //       icon: "bi-clipboard-minus",
  //       url: "/admin/clientconsignmentreport",
  //       parentKey: "clientconsignment",
  //     },
  //   ],
  // },
  {
    key: "member",
    label: i18n?.t("Members"),
    isTitle: false,
    icon: "bi-person-badge",
    children: [
      {
        key: "staff",
        label: i18n?.t("Staff"),
        isTitle: false,
        icon: "bi-person",
        // badge: { variant: "success", text: "4" },
        url: "/admin/staff",
        parentKey: "member",
      },
      // {
      //   key: "agent",
      //   label: "Agent",
      //   isTitle: false,
      //   icon: "bi-person",
      //   // badge: { variant: "success", text: "4" },
      //   url: "/admin/agent",
      //   parentKey: "member",
      // },
      {
        key: "driver",
        label: i18n?.t("Driver"),
        isTitle: false,
        icon: "bi-person",
        // badge: { variant: "success", text: "4" },
        url: "/admin/driver",
        parentKey: "member",
      },
      {
        key: "account",
        label: i18n?.t("Accounts"),
        isTitle: false,
        icon: "bi-person",
        // badge: { variant: "success", text: "4" },
        url: "/admin/Accounts",
        parentKey: "member",
      },
    ],
  },
  // { key: "ivms", label: "IVMS", isTitle: true },
  // {
  //   key: "drivers",
  //   label: "Drivers",
  //   isTitle: false,
  //   icon: "bi-dash",
  //   url: "/admin/drivers",
  // },
  // {
  //   key: "vehicle",
  //   label: "Vehicle",
  //   isTitle: false,
  //   icon: "bi-dash",
  //   url: "/admin/vehicle",
  // },
  // {
  //   key: "maintenancelog",
  //   label: "Maintenance Log",
  //   isTitle: false,
  //   icon: "bi-dash",
  //   url: "/admin/maintenancelog",
  // },
];

const SUPER_ADMIN_MASTER_MENU_ITEMS: MenuItemTypes[] = [
  {
    key: "master",
    label: i18n?.t("Masters"),
    isTitle: false,
    icon: "bi-globe-americas",
    children: [
      {
        key: "users",
        label: i18n?.t("Users"),
        parentKey: "master",
        icon: "bi-dash",
        // badge: { variant: "success", text: "4" },
        url: "/super-admin/users",
      },
      {
        key: "accountant",
        label: i18n?.t("Head Accountant"),
        parentKey: "master",
        icon: "bi-dash",
        // badge: { variant: "success", text: "4" },
        url: "/super-admin/accountant",
      },
      {
        key: "operations",
        label: i18n?.t("Operations Management"),
        parentKey: "master",
        icon: "bi-dash",
        // badge: { variant: "success", text: "4" },
        url: "/super-admin/operations-management",
      },
      {
        key: "agents",
        label: i18n?.t("Agents"),
        parentKey: "master",
        icon: "bi-dash",
        // badge: { variant: "success", text: "4" },
        url: "/super-admin/agent",
      },
      {
        key: "region",
        label: i18n?.t("Region"),
        parentKey: "master",
        icon: "bi-dash",
        // badge: { variant: "success", text: "4" },
        url: "/super-admin/region",
      },

      {
        key: "transport",
        label: i18n?.t("Transport"),
        icon: "bi-dash",
        parentKey: "master",
        url: "/super-admin/transport",
      },
      {
        key: "stoppage",
        label: i18n?.t("Stoppage"),
        icon: "bi-dash",
        parentKey: "master",
        url: "/super-admin/stoppage",
      },
      {
        key: "depots",
        label: i18n?.t("Depots"),
        icon: "bi-dash",
        parentKey: "master",
        url: "/super-admin/depot",
      },
      {
        key: "trip",
        label: i18n?.t("Trip"),
        icon: "bi-dash",
        parentKey: "master",
        url: "/super-admin/trip",
      },
      {
        key: "route",
        label: i18n?.t("Route"),
        parentKey: "master",
        icon: "bi-dash",
        url: "/super-admin/route",
      },
      {
        key: "priceList",
        label: i18n?.t("Price List"),
        icon: "bi-dash",
        parentKey: "master",
        url: "/super-admin/pricelist",
      },
      {
        key: "timingList",
        label: i18n?.t("Timing List"),
        icon: "bi-dash",
        parentKey: "master",
        url: "/super-admin/timinglist",
      },
    ],
  },

  {
    key: "roleManage",
    label: i18n?.t("Manage Role"),
    isTitle: false,
    icon: "bi-universal-access-circle",
    children: [
      {
        key: "accessRole",
        label: i18n?.t("Access Role"),
        isTitle: false,
        parentKey: "role",
        icon: "bi-dash",
        url: "/super-admin/accessrole",
      },
    ],
  },
];

export {
  AuthType,
  MENU_ITEMS,
  ADMIN_MENU_ITEMS,
  SUPER_ADMIN_MASTER_MENU_ITEMS,
  ACCOUNTS_MENU_ITEMS,
  SUPER_ACCOUNTS_MENU_ITEMS,
  OPERATION_MANAGER_ROUTES,
};
