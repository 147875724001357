import React from "react";
import { Route, RouteProps } from "react-router-dom";

// components
import PrivateRoute from "./PrivateRoute";

// import Root from './Root';

// lazy load all the views

// auth

const Login = React.lazy(() => import("../pages/auth/Login"));
const Logout = React.lazy(() => import("../pages/auth/Logout"));
const Confirm = React.lazy(() => import("../pages/auth/Confirm"));
const ForgetPassword = React.lazy(() => import("../pages/auth/ForgetPassword"));
const Register = React.lazy(() => import("../pages/auth/Register"));
const SignInSignUp = React.lazy(() => import("../pages/auth/SignInSignUp"));
const LockScreen = React.lazy(() => import("../pages/auth/LockScreen"));

// auth2
const Login2 = React.lazy(() => import("../pages/auth2/Login2"));
const Logout2 = React.lazy(() => import("../pages/auth2/Logout2"));
const Register2 = React.lazy(() => import("../pages/auth2/Register2"));
const Confirm2 = React.lazy(() => import("../pages/auth2/Confirm2"));
const ForgetPassword2 = React.lazy(() => import("../pages/auth2/ForgetPassword2"));
const LockScreen2 = React.lazy(() => import("../pages/auth2/LockScreen2"));
const SignInSignUp2 = React.lazy(() => import("../pages/auth2/SignInSignUp2"));

// landing
const Landing = React.lazy(() => import("../pages/landing/"));

// - chat
const RequestVisa = React.lazy(() => import("../pages/request_visa"));
const Upcoming = React.lazy(() => import("../pages/other/Upcoming"));
// const Maintenance = React.lazy(() => import("../pages/other/Maintenance"));
const Profile = React.lazy(() => import("../pages/profile"));

const Accommondation = React.lazy(() => import("../pages/accommondation"));
// const ExistFee = React.lazy(() => import("../pages/existfee"));
// const BookingReport = React.lazy(() => import("../pages/bookingreport"));
const Usercheckins = React.lazy(() => import("../pages/usercheckins"));
const VisaRequests = React.lazy(() => import("../pages/visarequests"));

// dashboard
const Dashboard = React.lazy(() => import("../pages/dashboard"));

const Driver = React.lazy(() => import("../pages/drivers"));
const Vehicles = React.lazy(() => import("../pages/vehicles"));
// const MaintenanceLog = React.lazy(() => import("../pages/maintenancelog"));
//
const Agent = React.lazy(() => import("../pages/SectionMembers/agent"));
const Drivers = React.lazy(() => import("../pages/SectionMembers/drivers"));
const Staff = React.lazy(() => import("../pages/SectionMembers/staff"));
const Accounts = React.lazy(() => import("../pages/SectionMembers/accounts"));

//masters route
const Users = React.lazy(() => import("../pages/masters/users"));
const Accountant = React.lazy(() => import("../pages/masters/accountant"));
const Operations = React.lazy(() => import("../pages/masters/Operations"));
const Region = React.lazy(() => import("../pages/masters/region"));
const Depots = React.lazy(() => import("../pages/masters/Depots"));
const Transport = React.lazy(() => import("../pages/masters/transport"));
const Stoppage = React.lazy(() => import("../pages/masters/stoppage"));
const Trip = React.lazy(() => import("../pages/masters/trip"));
const RoutePage = React.lazy(() => import("../pages/masters/route"));
const PriceList = React.lazy(() => import("../pages/masters/pricelist"));
const TimingList = React.lazy(() => import("../pages/masters/timing"));
const ManageRole = React.lazy(() => import("../pages/masters/managerole"));

// ------------------------------------------------------------------------------------------------------------ //

// apps staff
const BookPackage = React.lazy(() => import("../pages/SectionTicket/bookpackage"));
const CheckIn = React.lazy(() => import("../pages/SectionTicket/checkInList"));
const BookingRequest = React.lazy(() => import("../pages/SectionTicket/bookingrequest"));

// apps super-admin
const ExpenseReport = React.lazy(() => import("../pages/SectionReportsExpense/expensereport"));
const AddExpenses = React.lazy(() => import("../pages/SectionReportsExpense/addexpenses"));
const ExpenseHistory = React.lazy(() => import("../pages/SectionReportsExpense/expensehistory"));
const ReturnRequestHistory = React.lazy(() => import("../pages/SectionReportsExpense/returnRequestHistory"));
const DepositSlip = React.lazy(() => import("../pages/SectionReportsExpense/Deposit/depositSlip"));
const DepositReport = React.lazy(() => import("../pages/SectionReportsExpense/Deposit/depositReport"));
const TopUpReport = React.lazy(() => import("../pages/SectionReportsExpense/TopUp/TopUpReport"));
const TicketBooking = React.lazy(() => import("../pages/SectionTicket/ticketbooking"));
const RefundTicket = React.lazy(() => import("../pages/SectionTicket/refundBooking"));
const TravelDateChange = React.lazy(() => import("../pages/SectionTicket/traveldatechange"));
const BookingHistory = React.lazy(() => import("../pages/SectionTicket/bookinghistory"));
const SalesReport = React.lazy(() => import("../pages/SectionTicket/salesreport"));
const CreateConsignment = React.lazy(() => import("../pages/SectionConsignment/createconsignment"));
const ConsignmentHistory = React.lazy(() => import("../pages/SectionConsignment/consignmenthistory"));
const ConsignmentTracking = React.lazy(() => import("../pages/SectionConsignment/consignmenttracking"));
const ConsignmentTrackingHistory = React.lazy(() => import("../pages/SectionConsignment/consignmenttrackinghistory"));
const ConsignmentReport = React.lazy(() => import("../pages/SectionConsignment/consignmentreport"));
// const CreateClientConsignment = React.lazy(() => import("../pages/SectionConsignment/createclientconsignment"));
// const ClientConsignmentHistory = React.lazy(() => import("../pages/SectionConsignment/clientconsignmenthistory"));
// const ClientConsignmentReport = React.lazy(() => import("../pages/SectionConsignment/clientconsignmentreport"));

const BalanceReport = React.lazy(() => import("../pages/SectionReportsExpense/BalanceIncomeReport/BalanceReport"));
const IncomeStatement = React.lazy(() => import("../pages/SectionReportsExpense/BalanceIncomeReport/IncomeStatement"));
const TrialBalance = React.lazy(() => import("../pages/SectionReportsExpense/BalanceIncomeReport/TrialBalance"));
const AgentReport = React.lazy(() => import("../pages/SectionReportsExpense/agentReport"));

const Invoices = React.lazy(() => import("../pages/SectionOperations/Invoices"));
const BusMaintenance = React.lazy(() => import("../pages/SectionOperations/BusMaintenance"));
const UsersList = React.lazy(() => import("../pages/SectionReportsExpense/usersList"));

export interface RoutesProps {
  path: RouteProps["path"];
  name?: string;
  element?: RouteProps["element"];
  route?: any;
  exact?: boolean;
  icon?: string;
  header?: string;
  roles?: string[];
  children?: RoutesProps[];
}

const ProfileRoute = {
  path: "/profile",
  name: "Profile",
  route: PrivateRoute,
  element: <Profile />,
};

// Operations Management
const operationManagementRoutes: RoutesProps[] = [
  {
    path: "*",
    name: "Invoices",
    route: PrivateRoute,
    icon: "message-square",
    element: <Invoices />,
  },
  {
    path: "/operation-manager/bus-maintenances",
    name: "Bus Maintenances",
    route: PrivateRoute,
    icon: "message-square",
    element: <BusMaintenance />,
  },
  // {
  //   path: "/operation-manager/ticket-booking",
  //   name: "Ticket Booking",
  //   route: PrivateRoute,
  //   icon: "message-square",
  //   element: <BookPackage />,
  // },
  {
    path: "/operation-manager/expense-report",
    name: "Expense Report",
    icon: "airplay",
    element: <ExpenseReport />,
    route: PrivateRoute,
  },
  {
    path: "/operation-manager/add-expense/:id/edit",
    name: "Add Expense",
    icon: "airplay",
    element: <AddExpenses />,
    route: PrivateRoute,
  },
  {
    path: "/operation-manager/add-expense",
    name: "Add Expense",
    icon: "airplay",
    element: <AddExpenses />,
    route: PrivateRoute,
  },
  {
    path: "/operation-manager/expense-history",
    name: "Expense History",
    route: PrivateRoute,
    icon: "message-square",
    element: <ExpenseHistory />,
  },
  {
    path: "/operation-manager/add-deposit",
    name: "Deposit Create",
    route: PrivateRoute,
    icon: "message-square",
    element: <DepositSlip />,
  },
  {
    path: "/operation-manager/deposit-report",
    name: "Deposit Report",
    route: PrivateRoute,
    icon: "message-square",
    element: <DepositReport />,
  },
  {
    path: "/operation-manager/refund-request-history",
    name: "Refund History",
    route: PrivateRoute,
    icon: "message-square",
    element: <ReturnRequestHistory />,
  },
  {
    path: "/operation-manager/agent-report",
    name: "Agent Report",
    route: PrivateRoute,
    icon: "message-square",
    element: <AgentReport />,
  },
  {
    path: "/operation-manager/deposit-report",
    name: "Deposit Report",
    route: PrivateRoute,
    icon: "message-square",
    element: <DepositReport />,
  },
  {
    path: "/operation-manager/topup-report",
    name: "Deposit Report",
    route: PrivateRoute,
    icon: "message-square",
    element: <TopUpReport />,
  },
  // {
  //   path: "/operation-manager/update-booking",
  //   name: "Update Booking",
  //   route: PrivateRoute,
  //   icon: "message-square",
  //   element: <TicketBooking />,
  // },
  {
    path: "/operation-manager/travel-date-change-history",
    name: "Travel Date Change History",
    route: PrivateRoute,
    icon: "message-square",
    element: <TravelDateChange />,
  },
  {
    path: "/operation-manager/bookinghistory",
    name: "Booking History",
    route: PrivateRoute,
    icon: "message-square",
    element: <BookingHistory />,
  },
  {
    path: "/operation-manager/checkedinlist",
    name: "Check In List",
    route: PrivateRoute,
    icon: "message-square",
    element: <CheckIn />,
  },
  {
    path: "/operation-manager/salesreport",
    name: "Sales Report",
    route: PrivateRoute,
    icon: "message-square",
    element: <SalesReport />,
  },
  // {
  //   path: "/operation-manager/createconsignment",
  //   name: "Create Consignment",
  //   route: PrivateRoute,
  //   icon: "message-square",
  //   element: <CreateConsignment />,
  // },
  {
    path: "/operation-manager/consignmenthistory",
    name: "Consignment History",
    route: PrivateRoute,
    icon: "message-square",
    element: <ConsignmentHistory />,
  },
  // {
  //   path: "/operation-manager/consignmenttracking",
  //   name: "Consignment Tracking",
  //   route: PrivateRoute,
  //   icon: "message-square",
  //   element: <ConsignmentTracking />,
  // },
  // {
  //   path: "/operation-manager/consignment-tracking-history",
  //   name: "Consignment Tracking History",
  //   route: PrivateRoute,
  //   icon: "message-square",
  //   element: <ConsignmentTrackingHistory />,
  // },
  {
    path: "/operation-manager/consignmentreport",
    name: "Consignment Report",
    route: PrivateRoute,
    icon: "message-square",
    element: <ConsignmentReport />,
  },
  // {
  //   path: "/operation-manager/createclientconsignment",
  //   name: "Create Client Consignment",
  //   route: PrivateRoute,
  //   icon: "message-square",
  //   element: <CreateClientConsignment />,
  // },
  // {
  //   path: "/operation-manager/clientconsignmenthistory",
  //   name: "Client Consignment History",
  //   route: PrivateRoute,
  //   icon: "message-square",
  //   element: <ClientConsignmentHistory />,
  // },
  // {
  //   path: "/operation-manager/clientconsignmentreport",
  //   name: "Client Consignment Report",
  //   route: PrivateRoute,
  //   icon: "message-square",
  //   element: <ClientConsignmentReport />,
  // },
  {
    path: "/operation-manager/sales-report",
    name: "Sales Report",
    route: PrivateRoute,
    icon: "message-square",
    element: <SalesReport />,
  },
  {
    path: "/operation-manager/drivers",
    name: "Drivers",
    route: PrivateRoute,
    icon: "message-square",
    element: <Driver />,
  },
  {
    path: "/operation-manager/vehicle",
    name: "Vehicle",
    route: PrivateRoute,
    icon: "message-square",
    element: <Vehicles />,
  },
  // {
  //   path: "/operation-manager/maintenance-log",
  //   name: "Maintenance Log",
  //   route: PrivateRoute,
  //   icon: "message-square",
  //   element: <MaintenanceLog />,
  // },
  {
    path: "/operation-manager/agent",
    name: "Agent",
    route: PrivateRoute,
    element: <Agent />,
  },
  {
    path: "/operation-manager/staff",
    name: "Staff",
    route: PrivateRoute,
    element: <Staff />,
  },
  {
    path: "/operation-manager/driver",
    name: "Driver",
    route: PrivateRoute,
    element: <Drivers />,
  },
  {
    path: "/operation-manager/users-list",
    name: "Driver",
    route: PrivateRoute,
    element: <UsersList />,
  },
  ProfileRoute,
];

// super accounts routes head branch
const superAccountsRoutes: RoutesProps[] = [
  {
    path: "*",
    name: "Dashboard",
    icon: "airplay",
    element: <Dashboard />,
    route: PrivateRoute,
  },
  {
    path: "/super-accounts/expense-report",
    name: "Expense Report",
    icon: "airplay",
    element: <ExpenseReport />,
    route: PrivateRoute,
  },
  {
    path: "/super-accounts/expense-history",
    name: "Expense History",
    route: PrivateRoute,
    icon: "message-square",
    element: <ExpenseHistory />,
  },
  {
    path: "/super-accounts/refund-request-history",
    name: "Refund History",
    route: PrivateRoute,
    icon: "message-square",
    element: <ReturnRequestHistory />,
  },
  {
    path: "/super-accounts/update-booking",
    name: "Update Booking",
    route: PrivateRoute,
    icon: "message-square",
    element: <TicketBooking />,
  },
  {
    path: "/super-accounts/travel-date-change-history",
    name: "Travel Date Change History",
    route: PrivateRoute,
    icon: "message-square",
    element: <TravelDateChange />,
  },
  {
    path: "/super-accounts/bookinghistory",
    name: "Booking History",
    route: PrivateRoute,
    icon: "message-square",
    element: <BookingHistory />,
  },
  {
    path: "/super-accounts/salesreport",
    name: "Sales Report",
    route: PrivateRoute,
    icon: "message-square",
    element: <SalesReport />,
  },
  {
    path: "/super-accounts/agent-report",
    name: "Agent Report",
    route: PrivateRoute,
    icon: "message-square",
    element: <AgentReport />,
  },
  {
    path: "/super-accounts/consignmenthistory",
    name: "Consignment History",
    route: PrivateRoute,
    icon: "message-square",
    element: <ConsignmentHistory />,
  },
  {
    path: "/super-accounts/consignmenttracking",
    name: "Consignment Tracking",
    route: PrivateRoute,
    icon: "message-square",
    element: <ConsignmentTracking />,
  },
  {
    path: "/super-accounts/consignment-tracking-history",
    name: "Consignment Tracking History",
    route: PrivateRoute,
    icon: "message-square",
    element: <ConsignmentTrackingHistory />,
  },
  {
    path: "/super-accounts/consignmentreport",
    name: "Consignment Report",
    route: PrivateRoute,
    icon: "message-square",
    element: <ConsignmentReport />,
  },
  {
    path: "/super-accounts/checkedinlist",
    name: "Check In List",
    route: PrivateRoute,
    icon: "message-square",
    element: <CheckIn />,
  },
  {
    path: "/super-accounts/deposit-report",
    name: "Deposit Report",
    route: PrivateRoute,
    icon: "message-square",
    element: <DepositReport />,
  },
  {
    path: "/super-accounts/topup-report",
    name: "Deposit Report",
    route: PrivateRoute,
    icon: "message-square",
    element: <TopUpReport />,
  },
  {
    path: "/super-accounts/balance-report",
    name: "Balance Report",
    route: PrivateRoute,
    icon: "message-square",
    element: <BalanceReport />,
  },
  {
    path: "/super-accounts/incomereport",
    name: "Income Report",
    route: PrivateRoute,
    icon: "message-square",
    element: <IncomeStatement />,
  },
  {
    path: "/super-accounts/trial-balance",
    name: "Income Report",
    route: PrivateRoute,
    icon: "message-square",
    element: <TrialBalance />,
  },
  {
    path: "/super-accounts/users-list",
    name: "Driver",
    route: PrivateRoute,
    element: <UsersList />,
  },
  ProfileRoute,
];
// accounts routes
const accountsRoutes: RoutesProps[] = [
  {
    path: "/accounts/dashboard",
    name: "Dashboard",
    icon: "airplay",
    element: <Dashboard />,
    route: PrivateRoute,
  },
  {
    path: "/accounts/expense-report",
    name: "Expense Report",
    icon: "airplay",
    element: <ExpenseReport />,
    route: PrivateRoute,
  },
  {
    path: "/accounts/add-expense/:id/edit",
    name: "Add Expense",
    icon: "airplay",
    element: <AddExpenses />,
    route: PrivateRoute,
  },
  {
    path: "/accounts/add-expense",
    name: "Add Expense",
    icon: "airplay",
    element: <AddExpenses />,
    route: PrivateRoute,
  },
  {
    path: "/accounts/expense-history",
    name: "Expense History",
    route: PrivateRoute,
    icon: "message-square",
    element: <ExpenseHistory />,
  },
  {
    path: "/accounts/refund-request-history",
    name: "Refund History",
    route: PrivateRoute,
    icon: "message-square",
    element: <ReturnRequestHistory />,
  },
  {
    path: "/accounts/add-deposit",
    name: "Deposit Create",
    route: PrivateRoute,
    icon: "message-square",
    element: <DepositSlip />,
  },
  {
    path: "/accounts/deposit-report",
    name: "Deposit Report",
    route: PrivateRoute,
    icon: "message-square",
    element: <DepositReport />,
  },
  {
    path: "*",
    // path: "/accounts/ticket-booking",
    name: "Ticket Booking",
    route: PrivateRoute,
    icon: "message-square",
    element: <BookPackage />,
  },
  {
    path: "/accounts/update-booking",
    name: "Update Booking",
    route: PrivateRoute,
    icon: "message-square",
    element: <TicketBooking />,
  },
  {
    path: "/accounts/refund-booking",
    name: "Refund Ticket",
    route: PrivateRoute,
    icon: "message-square",
    element: <RefundTicket />,
  },
  {
    path: "/accounts/travel-date-change-history",
    name: "Travel Date Change History",
    route: PrivateRoute,
    icon: "message-square",
    element: <TravelDateChange />,
  },
  {
    path: "/accounts/bookinghistory",
    name: "Booking History",
    route: PrivateRoute,
    icon: "message-square",
    element: <BookingHistory />,
  },
  {
    path: "/accounts/salesreport",
    name: "Sales Report",
    route: PrivateRoute,
    icon: "message-square",
    element: <SalesReport />,
  },
  {
    path: "/accounts/createconsignment",
    name: "Create Consignment",
    route: PrivateRoute,
    icon: "message-square",
    element: <CreateConsignment />,
  },
  {
    path: "/accounts/consignmenthistory",
    name: "Consignment History",
    route: PrivateRoute,
    icon: "message-square",
    element: <ConsignmentHistory />,
  },
  {
    path: "/accounts/consignmenttracking",
    name: "Consignment Tracking",
    route: PrivateRoute,
    icon: "message-square",
    element: <ConsignmentTracking />,
  },
  {
    path: "/accounts/consignment-tracking-history",
    name: "Consignment Tracking History",
    route: PrivateRoute,
    icon: "message-square",
    element: <ConsignmentTrackingHistory />,
  },
  {
    path: "/accounts/consignmentreport",
    name: "Consignment Report",
    route: PrivateRoute,
    icon: "message-square",
    element: <ConsignmentReport />,
  },
  {
    path: "/accounts/checkedinlist",
    name: "Check In List",
    route: PrivateRoute,
    icon: "message-square",
    element: <CheckIn />,
  },
  ProfileRoute,
];

// ------- END --------

// staff user routes

const appRoutes: RoutesProps[] = [
  {
    path: "*",
    name: "Dashboard",
    icon: "airplay",
    element: <Dashboard />,
    route: PrivateRoute,
  },
  {
    path: "/staff/expense-report",
    name: "Expense Report",
    icon: "airplay",
    element: <ExpenseReport />,
    route: PrivateRoute,
  },
  {
    path: "/staff/refund-request-history",
    name: "Refund History",
    route: PrivateRoute,
    icon: "message-square",
    element: <ReturnRequestHistory />,
  },
  {
    path: "/staff/book-package",
    name: "Book Package",
    icon: "airplay",
    element: <BookPackage />,
    route: PrivateRoute,
  },
  {
    path: "/staff/travel-date-change-history",
    name: "Travel Date Change History",
    route: PrivateRoute,
    icon: "message-square",
    element: <TravelDateChange />,
  },
  {
    path: "/staff/update-booking",
    name: "Update Booking",
    route: PrivateRoute,
    icon: "message-square",
    element: <TicketBooking />,
  },
  {
    path: "/staff/request-visa",
    name: "Request Visa",
    route: PrivateRoute,
    icon: "message-square",
    element: <RequestVisa />,
  },
  {
    path: "/staff/accommondation",
    name: "Accommondation",
    route: PrivateRoute,
    icon: "message-square",
    element: <Accommondation />,
  },
  // {
  //   path: "/staff/existfee",
  //   name: "ExistFee",
  //   route: PrivateRoute,
  //   icon: "message-square",
  //   element: <ExistFee />,
  // },
  {
    path: "/staff/checkedlist",
    name: "CheckIn",
    route: PrivateRoute,
    icon: "message-square",
    element: <CheckIn />,
  },
  {
    path: "/staff/bookingrequest",
    name: "BookingRequest",
    route: PrivateRoute,
    icon: "message-square",
    // element: <BookingRequest />, // old route that has booking no
    element: <BookingHistory />,
  },
  {
    path: "/staff/visarequests",
    name: "VisaRequests",
    route: PrivateRoute,
    icon: "message-square",
    element: <VisaRequests />,
  },
  // {
  //   path: "/staff/booking-report",
  //   name: "BookingReport",
  //   route: PrivateRoute,
  //   icon: "message-square",
  //   element: <BookingReport />,
  // },
  {
    path: "/staff/salesreport",
    name: "Sales Report",
    route: PrivateRoute,
    icon: "message-square",
    element: <SalesReport />,
  },
  {
    path: "/staff/createconsignment",
    name: "Create Consignment",
    route: PrivateRoute,
    icon: "message-square",
    element: <CreateConsignment />,
  },
  {
    path: "/staff/consignmenthistory",
    name: "Consignment History",
    route: PrivateRoute,
    icon: "message-square",
    element: <ConsignmentHistory />,
  },
  {
    path: "/staff/consignmenttracking",
    name: "Consignment Tracking",
    route: PrivateRoute,
    icon: "message-square",
    element: <ConsignmentTracking />,
  },
  {
    path: "/staff/consignment-tracking-history",
    name: "Consignment Tracking History",
    route: PrivateRoute,
    icon: "message-square",
    element: <ConsignmentTrackingHistory />,
  },
  {
    path: "/staff/consignmentreport",
    name: "Consignment Report",
    route: PrivateRoute,
    icon: "message-square",
    element: <ConsignmentReport />,
  },
  ProfileRoute,
];

/* routes end */

//super admin master routes start

const masterRoutes: RoutesProps[] = [
  {
    path: "*",
    name: "Users",
    icon: "bi-person-badge-fill",
    element: <Users />,
    route: PrivateRoute,
  },
  {
    path: "/super-admin/accountant",
    name: "Accountant",
    icon: "bi-person-badge-fill",
    element: <Accountant />,
    route: PrivateRoute,
  },
  {
    path: "/super-admin/operations-management",
    name: "Operations Management",
    icon: "bi-person-badge-fill",
    element: <Operations />,
    route: PrivateRoute,
  },
  {
    path: "/super-admin/region",
    name: "Region",
    icon: "bi-globe-americas",
    element: <Region />,
    route: PrivateRoute,
  },
  {
    path: "/super-admin/depot",
    name: "Depots",
    icon: "bi-bus-front-fill",
    element: <Depots />,
    route: PrivateRoute,
  },
  {
    path: "/super-admin/transport",
    name: "Transport",
    icon: "bi-bus-front-fill",
    element: <Transport />,
    route: PrivateRoute,
  },
  {
    path: "/super-admin/stoppage",
    name: "Stoppage",
    icon: "bi-bus-front-fill",
    element: <Stoppage />,
    route: PrivateRoute,
  },
  {
    path: "/super-admin/trip",
    name: "Trip",
    icon: "bi-diagram-2-fill",
    element: <Trip />,
    route: PrivateRoute,
  },
  {
    path: "/super-admin/route",
    name: "Route",
    icon: "bi-sign-turn-slight-left-fill",
    element: <RoutePage />,
    route: PrivateRoute,
  },
  {
    path: "/super-admin/pricelist",
    name: "Price list",
    icon: "bi-cash-stack",
    element: <PriceList />,
    route: PrivateRoute,
  },
  {
    path: "/super-admin/timinglist",
    name: "Timing list",
    icon: "bi-cash-stack",
    element: <TimingList />,
    route: PrivateRoute,
  },
  {
    path: "/super-admin/accessrole",
    name: "Access Role",
    icon: "bi-universal-access-circle",
    element: <ManageRole />,
    route: PrivateRoute,
  },
  {
    path: "/super-admin/agent",
    name: "Agent",
    route: PrivateRoute,
    element: <Agent />,
  },
  ProfileRoute,
];

//  ----- routes end ----------

// admin user routes start

const superappRoutes: RoutesProps[] = [
  {
    path: "*",
    name: "Ticket Booking",
    route: PrivateRoute,
    icon: "message-square",
    element: <BookPackage />,
  },
  {
    path: "/admin/expense-report",
    name: "Expense Report",
    icon: "airplay",
    element: <ExpenseReport />,
    route: PrivateRoute,
  },
  {
    path: "/admin/add-expense/:id/edit",
    name: "Add Expense",
    icon: "airplay",
    element: <AddExpenses />,
    route: PrivateRoute,
  },
  {
    path: "/admin/add-expense",
    name: "Add Expense",
    icon: "airplay",
    element: <AddExpenses />,
    route: PrivateRoute,
  },
  {
    path: "/admin/expense-history",
    name: "Expense History",
    route: PrivateRoute,
    icon: "message-square",
    element: <ExpenseHistory />,
  },
  {
    path: "/admin/refund-request-history",
    name: "Refund History",
    route: PrivateRoute,
    icon: "message-square",
    element: <ReturnRequestHistory />,
  },
  {
    path: "/admin/add-deposit",
    name: "Deposit Create",
    route: PrivateRoute,
    icon: "message-square",
    element: <DepositSlip />,
  },
  {
    path: "/admin/deposit-report",
    name: "Deposit Report",
    route: PrivateRoute,
    icon: "message-square",
    element: <DepositReport />,
  },
  {
    path: "/admin/update-booking",
    name: "Update Booking",
    route: PrivateRoute,
    icon: "message-square",
    element: <TicketBooking />,
  },
  {
    path: "/admin/refund-booking",
    name: "Refund Ticket",
    route: PrivateRoute,
    icon: "message-square",
    element: <RefundTicket />,
  },
  {
    path: "/admin/travel-date-change-history",
    name: "Travel Date Change History",
    route: PrivateRoute,
    icon: "message-square",
    element: <TravelDateChange />,
  },
  {
    path: "/admin/bookinghistory",
    name: "Booking History",
    route: PrivateRoute,
    icon: "message-square",
    element: <BookingHistory />,
  },
  {
    path: "/admin/checkedinlist",
    name: "Check In List",
    route: PrivateRoute,
    icon: "message-square",
    element: <CheckIn />,
  },
  {
    path: "/admin/salesreport",
    name: "Sales Report",
    route: PrivateRoute,
    icon: "message-square",
    element: <SalesReport />,
  },
  {
    path: "/admin/createconsignment",
    name: "Create Consignment",
    route: PrivateRoute,
    icon: "message-square",
    element: <CreateConsignment />,
  },
  {
    path: "/admin/consignmenthistory",
    name: "Consignment History",
    route: PrivateRoute,
    icon: "message-square",
    element: <ConsignmentHistory />,
  },
  {
    path: "/admin/consignmenttracking",
    name: "Consignment Tracking",
    route: PrivateRoute,
    icon: "message-square",
    element: <ConsignmentTracking />,
  },
  {
    path: "/admin/consignment-tracking-history",
    name: "Consignment Tracking History",
    route: PrivateRoute,
    icon: "message-square",
    element: <ConsignmentTrackingHistory />,
  },
  {
    path: "/admin/consignmentreport",
    name: "Consignment Report",
    route: PrivateRoute,
    icon: "message-square",
    element: <ConsignmentReport />,
  },
  // {
  //   path: "/admin/createclientconsignment",
  //   name: "Create Client Consignment",
  //   route: PrivateRoute,
  //   icon: "message-square",
  //   element: <CreateClientConsignment />,
  // },
  // {
  //   path: "/admin/clientconsignmenthistory",
  //   name: "Client Consignment History",
  //   route: PrivateRoute,
  //   icon: "message-square",
  //   element: <ClientConsignmentHistory />,
  // },
  // {
  //   path: "/admin/clientconsignmentreport",
  //   name: "Client Consignment Report",
  //   route: PrivateRoute,
  //   icon: "message-square",
  //   element: <ClientConsignmentReport />,
  // },
  {
    path: "/admin/drivers",
    name: "Drivers",
    route: PrivateRoute,
    icon: "message-square",
    element: <Driver />,
  },
  {
    path: "/admin/vehicle",
    name: "Vehicle",
    route: PrivateRoute,
    icon: "message-square",
    element: <Vehicles />,
  },
  // {
  //   path: "/admin/maintenancelog",
  //   name: "Maintenance Log",
  //   route: PrivateRoute,
  //   icon: "message-square",
  //   element: <MaintenanceLog />,
  // },
  {
    path: "/admin/agent",
    name: "Agent",
    route: PrivateRoute,
    element: <Agent />,
  },
  {
    path: "/admin/staff",
    name: "Staff",
    route: PrivateRoute,
    element: <Staff />,
  },
  {
    path: "/admin/driver",
    name: "Driver",
    route: PrivateRoute,
    element: <Drivers />,
  },
  {
    path: "/admin/Accounts",
    name: "Accounts",
    route: PrivateRoute,
    element: <Accounts />,
  },
  ProfileRoute,
];

// admin user routes end

// auth
const authRoutes: RoutesProps[] = [
  {
    path: "/admin/login",
    name: "Login",
    element: <Login />,
    route: Route,
  },
  {
    path: "/auth/register",
    name: "Register",
    element: <Register />,
    route: Route,
  },
  {
    path: "/auth/confirm",
    name: "Confirm",
    element: <Confirm />,
    route: Route,
  },
  {
    path: "/auth/forget-password",
    name: "Forget Password",
    element: <ForgetPassword />,
    route: Route,
  },
  {
    path: "/auth/signin-signup",
    name: "SignIn-SignUp",
    element: <SignInSignUp />,
    route: Route,
  },
  {
    path: "/auth/lock-screen",
    name: "Lock Screen",
    element: <LockScreen />,
    route: Route,
  },
  {
    path: "/auth/logout",
    name: "Logout",
    element: <Logout />,
    route: Route,
  },
  {
    path: "/auth/login2",
    name: "Login2",
    element: <Login2 />,
    route: Route,
  },
  {
    path: "/auth/logout2",
    name: "Logout2",
    element: <Logout2 />,
    route: Route,
  },
  {
    path: "/auth/register2",
    name: "Register2",
    element: <Register2 />,
    route: Route,
  },
  {
    path: "/auth/confirm2",
    name: "Confirm2",
    element: <Confirm2 />,
    route: Route,
  },
  {
    path: "/auth/forget-password2",
    name: "Forget Password2",
    element: <ForgetPassword2 />,
    route: Route,
  },
  {
    path: "/auth/signin-signup2",
    name: "SignIn-SignUp2",
    element: <SignInSignUp2 />,
    route: Route,
  },
  {
    path: "/auth/lock-screen2",
    name: "Lock Screen2",
    element: <LockScreen2 />,
    route: Route,
  },
];

// public routes
const otherPublicRoutes = [
  {
    path: "/landing",
    name: "landing",
    element: <Landing />,
    route: Route,
  },
  // {
  //   path: "/maintenance",
  //   name: "Maintenance",
  //   element: <Maintenance />,
  //   route: Route,
  // },
  {
    path: "/upcoming",
    name: "Coming Soon",
    element: <Upcoming />,
    route: Route,
  },
  {
    path: "/checkin",
    name: "User Checkin",
    element: <Usercheckins />,
    route: Route,
  },
];

// flatten the list of all nested routes
const flattenRoutes = (routes: RoutesProps[]) => {
  let flatRoutes: RoutesProps[] = [];

  routes = routes || [];
  routes.forEach((item: RoutesProps) => {
    flatRoutes.push(item);

    if (typeof item.children !== "undefined") {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

// All routes

const publicProtectedFlattenRoutes = flattenRoutes([
  ...authRoutes,
  // ...otherPublicRoutes,
]);

const authProtectedFlattenRoutes = flattenRoutes([...appRoutes]);
const adminAuthProtectedRoutes = flattenRoutes([...superappRoutes]);
const superAdminMasterAuthProtectedFlattenRoutes = flattenRoutes([
  ...masterRoutes,
]);
const accountsFlattenRoutes = flattenRoutes([...accountsRoutes]);
const superAccountsFlattenRoutes = flattenRoutes([...superAccountsRoutes]);
const superOperationManagementRoutes = flattenRoutes([
  ...operationManagementRoutes,
]);
export {
  authProtectedFlattenRoutes,
  adminAuthProtectedRoutes,
  superAdminMasterAuthProtectedFlattenRoutes,
  publicProtectedFlattenRoutes,
  accountsFlattenRoutes,
  superAccountsFlattenRoutes,
  superOperationManagementRoutes,
};
