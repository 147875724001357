import { APICore } from "./apiCore";
import { getAllTripList, loginUrl, logOut, userDetails, getAllBookingRouteListUrl} from "./apiUrls";

const api = new APICore();

// account
function login(params: { email: string; password: string }) {
  return api.create(loginUrl, params);
}

function logout() {
  return api.create(logOut, {});
}

function getUserDetails() {
  return api.create(userDetails, {});
}




function forgotPassword(params: { username: string }) {
  const baseUrl = "/forget-password/";
  return api.create(`${baseUrl}`, params);
}

export { login, logout, forgotPassword,getUserDetails};
